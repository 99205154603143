// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-1-click-framework-installer-js": () => import("./../../../src/pages/1-click-framework-installer.js" /* webpackChunkName: "component---src-pages-1-click-framework-installer-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-administration-services-js": () => import("./../../../src/pages/administration-services.js" /* webpackChunkName: "component---src-pages-administration-services-js" */),
  "component---src-pages-advanced-tools-js": () => import("./../../../src/pages/advanced-tools.js" /* webpackChunkName: "component---src-pages-advanced-tools-js" */),
  "component---src-pages-complimentary-web-site-building-tool-js": () => import("./../../../src/pages/complimentary-web-site-building-tool.js" /* webpackChunkName: "component---src-pages-complimentary-web-site-building-tool-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cpanel-comparison-js": () => import("./../../../src/pages/cpanel-comparison.js" /* webpackChunkName: "component---src-pages-cpanel-comparison-js" */),
  "component---src-pages-databases-manager-js": () => import("./../../../src/pages/databases-manager.js" /* webpackChunkName: "component---src-pages-databases-manager-js" */),
  "component---src-pages-dedicated-hosting-client-support-service-js": () => import("./../../../src/pages/dedicated-hosting-client-support-service.js" /* webpackChunkName: "component---src-pages-dedicated-hosting-client-support-service-js" */),
  "component---src-pages-dedicated-server-js": () => import("./../../../src/pages/dedicated-server.js" /* webpackChunkName: "component---src-pages-dedicated-server-js" */),
  "component---src-pages-domain-names-manager-js": () => import("./../../../src/pages/domain-names-manager.js" /* webpackChunkName: "component---src-pages-domain-names-manager-js" */),
  "component---src-pages-domain-search-js": () => import("./../../../src/pages/domain-search.js" /* webpackChunkName: "component---src-pages-domain-search-js" */),
  "component---src-pages-domains-js": () => import("./../../../src/pages/domains.js" /* webpackChunkName: "component---src-pages-domains-js" */),
  "component---src-pages-file-manager-js": () => import("./../../../src/pages/file-manager.js" /* webpackChunkName: "component---src-pages-file-manager-js" */),
  "component---src-pages-free-themes-js": () => import("./../../../src/pages/free-themes.js" /* webpackChunkName: "component---src-pages-free-themes-js" */),
  "component---src-pages-hosting-js": () => import("./../../../src/pages/hosting.js" /* webpackChunkName: "component---src-pages-hosting-js" */),
  "component---src-pages-hosting-platform-js": () => import("./../../../src/pages/hosting-platform.js" /* webpackChunkName: "component---src-pages-hosting-platform-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mail-accounts-manager-js": () => import("./../../../src/pages/mail-accounts-manager.js" /* webpackChunkName: "component---src-pages-mail-accounts-manager-js" */),
  "component---src-pages-online-site-installer-js": () => import("./../../../src/pages/online-site-installer.js" /* webpackChunkName: "component---src-pages-online-site-installer-js" */),
  "component---src-pages-order-form-js": () => import("./../../../src/pages/order-form.js" /* webpackChunkName: "component---src-pages-order-form-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-pdf-catalogs-js": () => import("./../../../src/pages/pdf-catalogs.js" /* webpackChunkName: "component---src-pages-pdf-catalogs-js" */),
  "component---src-pages-semi-dedicated-hosting-client-support-service-js": () => import("./../../../src/pages/semi-dedicated-hosting-client-support-service.js" /* webpackChunkName: "component---src-pages-semi-dedicated-hosting-client-support-service-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-vps-client-support-service-js": () => import("./../../../src/pages/vps-client-support-service.js" /* webpackChunkName: "component---src-pages-vps-client-support-service-js" */),
  "component---src-pages-vps-js": () => import("./../../../src/pages/vps.js" /* webpackChunkName: "component---src-pages-vps-js" */),
  "component---src-pages-vps-packages-js": () => import("./../../../src/pages/vps-packages.js" /* webpackChunkName: "component---src-pages-vps-packages-js" */),
  "component---src-pages-warranties-js": () => import("./../../../src/pages/warranties.js" /* webpackChunkName: "component---src-pages-warranties-js" */),
  "component---src-pages-web-applications-js": () => import("./../../../src/pages/web-applications.js" /* webpackChunkName: "component---src-pages-web-applications-js" */),
  "component---src-pages-web-control-panel-js": () => import("./../../../src/pages/web-control-panel.js" /* webpackChunkName: "component---src-pages-web-control-panel-js" */),
  "component---src-pages-web-stats-manager-js": () => import("./../../../src/pages/web-stats-manager.js" /* webpackChunkName: "component---src-pages-web-stats-manager-js" */),
  "component---src-pages-website-accelerator-tools-js": () => import("./../../../src/pages/website-accelerator-tools.js" /* webpackChunkName: "component---src-pages-website-accelerator-tools-js" */),
  "component---src-pages-website-hosting-client-support-service-js": () => import("./../../../src/pages/website-hosting-client-support-service.js" /* webpackChunkName: "component---src-pages-website-hosting-client-support-service-js" */),
  "component---src-pages-website-hosting-comparison-js": () => import("./../../../src/pages/website-hosting-comparison.js" /* webpackChunkName: "component---src-pages-website-hosting-comparison-js" */),
  "component---src-pages-website-migration-js": () => import("./../../../src/pages/website-migration.js" /* webpackChunkName: "component---src-pages-website-migration-js" */),
  "component---src-pages-why-us-js": () => import("./../../../src/pages/why-us.js" /* webpackChunkName: "component---src-pages-why-us-js" */)
}

